<template>
    <div class="dashboard-table-card-container ">
      <le-card width="100%" style="padding-bottom:20px" >
        <template>
          <div class="container">
            <div class="title">账号权限
              <div class="num-box">{{pageOptions.total}}人</div>
            </div>
            <div class="table-box">
                <a-table 
                :columns="columns" 
                :data-source="dataSource"
                :loading="loading"
                :pagination="pageOptions"
                @change="handleTableChange"
                :scroll="{ x: 600,y:680 }"
                size="middle" >

                <div slot="uname"  slot-scope="row">
                  <a class="name-box" :href="'https://space.bilibili.com/'+row.anchor_uid" target="_blank" rel="noopener noreferrer">
                    <div class="avatar"  >
                      <img :src="row.face_url" alt="" srcset="">
                      
                      </div>
                    <div class="name-uid">
                      <div>{{row.name}}</div>
                      <div>{{row.anchor_uid}}</div>
                    </div>
                  </a>
                </div>

                <div slot="is_sign" slot-scope="row">
                  <div style="color:#4ACC7F"  v-if="row.is_sign==0">未签约</div>
                  <div style="color:#FF6363"  v-else>有工会或官签</div>
                </div>

                <span slot="customTitle">开播率 
                   <a-tooltip>
                  <template slot="title">
                    近3天直播过 记为有效
                  </template>
                    <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>

                  <span slot="action" style="display: flex" slot-scope="row" >
                    <le-button txt="刷新" width="40px" height="30px" fontSize="14px" :loading="row.isLoad"  @btnClick="row.isLoad=true;clickRefresh(row)" />
                    <le-button txt="编辑" width="40px" height="30px" fontSize="14px" @btnClick="clickEdit(row)" style="margin-left:10px" />
                    <div class="btn-canle" v-if="row.order==1" @click="clickTop(row.id,0)" style="margin-left:10px">取消置顶</div>
                    <le-button txt="置顶" width="40px" height="30px" fontSize="14px" @btnClick="clickTop(row.id,1)" style="margin-left:10px" v-else />
                    <le-button txt="删除" width="40px" height="30px" fontSize="14px" @btnClick="clickDel(row.id)" style="margin-left:10px" />
                  </span>

                  <span slot="action-r" >
                    <le-button txt="请求" width="40px" height="30px" fontSize="14px" v-if="isGet" />
                  </span>

              </a-table>
            </div>
          </div>
        </template>
      </le-card>
      <popEdit v-if="visible" :visible="visible" :row="rows"  @handleOk="handleOk"  @handleCancel="handleCancel"   />
    </div>
</template>

<script>

const columns = [
  {
    title: '昵称/UID',
    scopedSlots: { customRender: "uname" },
    width:200
  },
  {
    title: '状态',
    // dataIndex: 'uid',
    width: 100,
    scopedSlots: { customRender: "is_sign" }
  },
  {
    title: '粉丝',
    // dataIndex: 'uid',
    width: 100,
    dataIndex:'follower'
  },
    {
    title: '流水（ ￥ ）',
    width: 100,
    dataIndex: 'income'
  },
    {
    title: '直播时长',
    width: 100,
    dataIndex: 'live_time'
  },
  {
    title: '联系方式',
    width: 110,
    dataIndex: 'phone',
  },
  {
    title:'备注',
    width: 120,
    dataIndex: 'notes'
  },
  {
    title: '查询时间',
    width: 150,
    dataIndex: 'created_at',
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    scopedSlots: { customRender: "action" }
  },
  {
    title: '粉丝评分',
    width: 100,
    key: 'follower_index',
    dataIndex: 'follower_index',
    fixed: 'right',
  },
  {
    title: '大航海评分',
    width: 100,
    key: 'guard_level',
    dataIndex: 'guard_level',
    fixed: 'right',
  },
  {
    title: '流水评分',
    width: 100,
    key: 'income_index',
    dataIndex: 'income_index',
    fixed: 'right',
  },
  {
    title: '总评分',
    width: 100,
    key: 'total_index',
    dataIndex: 'total_index',
    fixed: 'right',
  },
  {
    title: '操作',
    width: 100,
    key:"action-r",
    scopedSlots: { customRender: "action-r" },
    fixed: 'right',
  },


];

import popEdit from './popEdit'
import {MixinList} from '@/common/mixin';
export default {
  components: {
    popEdit
  },
  data() {
    return {
      columns,
      dataUrl:'v1/user/anchor/query_history',
      delUrl:'admin/layout/del',
      visible: false,
      isGet: false,
      rows:{}
    };
  },
  computed: {
  },
  mixins:[MixinList],
  beforeMount(){
    this.queryParam.is_sign = 0
  },
  created(){
    let btn=window.localStorage.getItem('whiteBtns')
    if(btn){
      let btns=btn.split(',');
      if(btns.includes('broker-auth-get')){
        this.isGet = true;
      }
    }
  },

  mounted(){
    
  },
  methods: {
    changeTab(index){
      console.log('index',index)
      this.queryParam.is_sign=index
      this.queryParam.page=1
      this.getData()
    },
    clickEdit(row){
      console.log('222', row)
      this.rows=row
      this.visible = true;
    },
    handleOk(){
      this.visible = false;
      this.getData()
    },
    handleCancel(){
      this.visible = false;
      this.getData()
    },
    clickDel(id){
      this.$api.post(`v1/user/anchor/query_history/delete`,{id}).then(res=>{
        if(res.code==200){
          this.$message.success('删除成功')
          this.getData()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    clickTop(id,order){
      console.log(id)
      this.$api.post(`v1/user/anchor/query_history_edit`,{id,order}).then(res=>{
        console.log(res)
        if(res.code==200){
          this.getData()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    clickRefresh(row){
      // this.$api.post(`v1/user/anchor/query_history/refresh`,{anchor_uid}).then(res=>{
      this.$api.post(`/v1/user/anchor/refresh`,{uid:row.anchor_uid}).then(res=>{
        console.log(res)
        if(res.code==200){
          this.getData()
        }else{
          row.isLoad=false
          this.$message.error(res.message)
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-table-card-container {
.container{
  position: relative;
  .title{
    padding-top: 20px;
    margin-left: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: flex-end;
    .num-box{
      font-size: 18px;
      margin-left: 11px;
      line-height: 32px;
    }
  }
  .le-button{
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .table-box{
    margin: 40px 30px;
  }
}
}

.btn-canle{
  width: 70px;
  height: 30px;
  background: linear-gradient(-10deg, #FD8A8F, #FA6A9D);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color:#fff;
  margin-left: 10px;
}

.login{
  cursor: pointer;
  &:hover{
    color: #008DF0;
  }
}

.name-box{
  display: flex;
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img{
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
.dashboard-table-card-container {
.container{
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 20px;
    .num-box{
      font-size: 12px;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .le-button{
    position: absolute;
    top: 30px;
    right: 20px;
  }

  .table-box{
    margin: 20px;
    overflow-x: scroll;
  }
}
}
}

</style>
